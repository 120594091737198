import { Row, Col, Typography, Empty } from 'antd';
import { useEffect, useState } from 'react';
import { getOrders } from '../services/accountOrders';
import './../styles/OrderIndex.scss';
import { FSR_ORDER_STATUS } from '../types/enums';
import { Footer } from '../elements/Footer';
import { TopBar } from '../elements/TopBar';
import { useTranslation } from 'react-i18next';
import CircularProgressBar from '../elements/CircularProgressBar';
import { Order } from '../elements/Order';
import { OrderFilter } from '../elements/OrderFilter';
import { AppSearch } from '../elements/AppSearch';
import { BASE_TITLE } from '../utils/utility';

const initialMeta = {
	ALL: 0,
	[FSR_ORDER_STATUS.COMPLETED]: 0,
	[FSR_ORDER_STATUS.NEEDATTENTION]: 0,
	[FSR_ORDER_STATUS.INPROGRESS]: 0,
};

// returns oder index screen
export const OrderIndex = () => {
	// can set true if actual api call is going on
	const [loading, setLoading] = useState(false);

	// can set true after screen is initialized
	const [init, setInit] = useState(false);

	// can set account details
	const [accountDetails, setAccountDetails] = useState([]);

	// can set total count for each filter status of the orders
	const [meta, setMeta] = useState(initialMeta);

	// can set the active order filter of the screen
	const [activeFilter, setActiveFilter] = useState(FSR_ORDER_STATUS.ALL);

	// can set order filter text
	const [searchText, setSearchText] = useState('');

	// can set active api offset of orders
	const [page, setPage] = useState('0');

	// orders limit for a single api call is limited to 10
	const limit = 10;

	// can set whether actual api call should be executed after api parameters are being changed
	const [mustSearch, setMustSearch] = useState(true);

	// can set reset account list
	const [resetAccountList, setResetAccountList] = useState(true);

	// init localization
	const { t } = useTranslation();

	// sets active filter only if the new filter is not equal to active filter
	const setFilter = filterName => {
		if (filterName === activeFilter) {
			return;
		}
		setAccountDetails([]);
		setPage('0');
		setActiveFilter(filterName);
		setResetAccountList(true);
		setMustSearch(true);
	};

	// functional wrapper for load order api call with handling
	const loadOrders = () => {
		// if actual api call is executing, not allowing to execute another
		if (loading) {
			return;
		}
		setLoading(true);

		// sets api params here
		const params = {
			limit: limit.toString(),
			offset: page,
			orderStatus: activeFilter,
			accountInfo: 'true',
			searchParam: searchText,
		};

		// if the status is ALL, remove the api parameter for status
		if (activeFilter === FSR_ORDER_STATUS.ALL) {
			delete params.orderStatus;
		}

		// here executes the actiual api call
		getOrders(params)
			.then(response => {
				// if the result is empty or has an error exit from further processing
				let meta;
				if (response.length < 2 || response.error) {
					setLoading(false);
					if(response.length === 1){
						meta = response.shift().meta;

						// sets filter reated counts
						if(meta && meta.order_summary){
							setMeta(meta.order_summary);
						}
					}else{
						setMeta(initialMeta);
					}
					
					return;
				}

				// sets filter reated counts
				setMeta(response.shift().meta.order_summary);

				// process the response to get orders list
				const orders = response.map(order => {
					return {
						orderNum: order.order_number.split('-')[0],
						accountName: order.shitpto_cus_name,
						accountId: order.cus_shipto_num,
						cacNumber: order.cus_cac_num,
						poNumber: order.po_number,
						requestedDate: order.order_placement_date,
						totalAmount: order.total_price,
						currencyCode: order.currency,
						updatedDate: order.order_last_update,
						prcntComplete: order.ordr_prcnt_complete,
						orderStatus: order.order_status,
						orderRecordId: order.order_number,
					};
				});

				// set order details to show based on set ot or not
				setAccountDetails(
					resetAccountList
						? [].concat.apply([], orders)
						: accountDetails.concat([].concat.apply([], orders)),
				);
				setInit(true);
				setLoading(false);
			})
			.catch(() => {
				setMeta(initialMeta);
				setLoading(false);
			});
	};

	// sets page title
	useEffect(() => {
		document.title = BASE_TITLE + ' - Order Index';
	}, []);

	useEffect(() => {
		// api executes only if mustSearch is true
		if (mustSearch) {
			setMustSearch(false);
			loadOrders();
		}
	}, [activeFilter, page, searchText, mustSearch]);

	const initSearch = () => {
		document.activeElement.blur();
		setAccountDetails([]);
		setPage('0');
		setResetAccountList(true);
		setMustSearch(true);
		window.focus();
		document.activeElement.blur();
	};

	const setOrder = (order, key) => (
		<Order
			key={key}
			order={order}
			loading={loading && !init}
			withNavigation={true}
			accountId={order.cacNumber}
			allOrders={true}
		/>
	);

	return (
		<div className="common-page-comtainer">
			<TopBar backVisibility={false} />
			<Row justify="space-around" align="middle" className="order-index">
				<Col span={23} className="col-container">
					<Row
						justify="space-around"
						align="middle"
						className="main-topic-height">
						<Col span={24} className="header-title">
							<Typography.Title level={5} className="page-header-text">
								{t('orders')}
							</Typography.Title>
						</Col>
					</Row>
					<Row className="search-height">
						<Col span={24}>
							<AppSearch
								placeholder={t('searchOrder')}
								onChange={event => {
									setSearchText(event.target.value);
									if (event.target.value === '') {
										initSearch();
									}
								}}
								onPressSearch={initSearch}
								onPressEnter={initSearch}
							/>
						</Col>
					</Row>
					<Row align="middle" className="filter-buttons-height">
						<Col span={6}>
							<OrderFilter
								onClick={() => {
									setFilter(FSR_ORDER_STATUS.ALL);
								}}
								text={(meta && meta['ALL'] !== undefined && meta['ALL'] !== null)?meta['ALL']:0}
								activeFilter={activeFilter}
								filter={FSR_ORDER_STATUS.ALL}
							/>
						</Col>
						<Col span={6}>
							<OrderFilter
								onClick={() => {
									setFilter(FSR_ORDER_STATUS.NEEDATTENTION);
								}}
								text={(meta && meta[FSR_ORDER_STATUS.NEEDATTENTION])?meta[FSR_ORDER_STATUS.NEEDATTENTION]:0}
								activeFilter={activeFilter}
								filter={FSR_ORDER_STATUS.NEEDATTENTION}
							/>
						</Col>
						<Col span={6}>
							<OrderFilter
								onClick={() => {
									setFilter(FSR_ORDER_STATUS.INPROGRESS);
								}}
								text={(meta && meta[FSR_ORDER_STATUS.INPROGRESS])?meta[FSR_ORDER_STATUS.INPROGRESS]:0}
								activeFilter={activeFilter}
								filter={FSR_ORDER_STATUS.INPROGRESS}
							/>
						</Col>
						<Col span={6}>
							<OrderFilter
								onClick={() => {
									setFilter(FSR_ORDER_STATUS.COMPLETED);
								}}
								text={(meta && meta[FSR_ORDER_STATUS.COMPLETED])?meta[FSR_ORDER_STATUS.COMPLETED]:0}
								activeFilter={activeFilter}
								filter={FSR_ORDER_STATUS.COMPLETED}
							/>
						</Col>
					</Row>
					<Row
						justify="space-around"
						align="middle"
						className="sub-topic-height">
						<Col span={24}>
							<Typography.Title className="filter-name-text" level={5}>
								{t(activeFilter)}
							</Typography.Title>
						</Col>
					</Row>
					<Row
						justify="space-around"
						align="middle"
						className="order-container"
						onScroll={e => {
							const bottom =
								e.target.scrollHeight -
								e.target.scrollTop -
								e.target.clientHeight;
							if (
								bottom <= 2 &&
								accountDetails.length > 0 &&
								!loading &&
								accountDetails.length === (Number(page) + 1) * limit
							) {
								setResetAccountList(false);
								setPage(Number(page) + 1 + '');
								setMustSearch(true);
							}
						}}>
						{accountDetails
							.filter(order => {
								return order.orderStatus === FSR_ORDER_STATUS.NEEDATTENTION;
							})
							.map((order, index) =>
								setOrder(order, 'need-attention-' + index.toString()),
							)}
						{accountDetails
							.filter(order => {
								return order.orderStatus === FSR_ORDER_STATUS.INPROGRESS;
							})
							.map((order, index) =>
								setOrder(order, 'in-progress-' + index.toString()),
							)}
						{accountDetails
							.filter(order => {
								return order.orderStatus === FSR_ORDER_STATUS.COMPLETED;
							})
							.map((order, index) =>
								setOrder(order, 'need-completed-' + index.toString()),
							)}
						{activeFilter === FSR_ORDER_STATUS.ALL &&
							accountDetails
								.filter(order => {
									return order.orderStatus === null || order.orderStatus === "";
								})
								.map((order, index) =>
									setOrder(order, 'unknown-' + index.toString()),
								)}
						{accountDetails.length === 0 && !loading && <Empty />}
						{/* {
                            loading  && <Spin indicator={<Image src="/icons/loading.gif" />} />
                        } */}
						{loading && (
							<CircularProgressBar
								percentage={10}
								status="exception"
								width={50}
							/>
						)}
					</Row>
				</Col>
			</Row>
			<Footer />
		</div>
	);
};
