import { Image, Input } from 'antd';
import './../styles/AccountOrders.scss';

export const AppSearch = ({
	placeholder,
	onChange,
	onPressSearch,
	onPressEnter,
}) => {
	return (
		<Input
			placeholder={placeholder}
			onChange={onChange}
			className="search"
			prefix={
				<Image
					preview={false}
					src="/icons/search-icon.png"
					onClick={onPressSearch}
				/>
			}
			onPressEnter={onPressEnter}
			allowClear={true}
			onFocus={() => {
				document.getElementsByClassName('portraitMode')[0].classList.add('search-active');
				document.getElementsByClassName('landscapeMode')[0].classList.add('hidden');
			}}
			onBlur={() => {
				document.getElementsByClassName('portraitMode')[0].classList.remove('search-active');
				document.getElementsByClassName('landscapeMode')[0].classList.remove('hidden');
			}}
		/>
	);
};
