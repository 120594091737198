import { Row, Col, Typography, Image, Button } from 'antd';
import '../styles/HelpExample.scss';
import { TopBar } from '../elements/TopBar';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { touchEnd, touchStart, touchMove } from '../utils/touchfunctionality';
import { useEffect, useState } from 'react';
import { BASE_TITLE } from '../utils/utility';

export const isIOS13 = () => (navigator.appVersion !== undefined && navigator.appVersion.indexOf('iPhone OS 13') > -1);

export const HelpExample = () => {
	const { push } = useHistory();
	const pagex = 0;
	const currentPageX = 0;
	const { t } = useTranslation();
	const history = useHistory();
	const [lang] = useState(localStorage.getItem('lang'));
	const { pathname } = useLocation();

	// sets page title
	useEffect(() => {
		document.title = BASE_TITLE + ' - Help - Example';
	}, []);
	useEffect(() => {
		localStorage.setItem('backUrl', '/help-order-status-work');
	}, [pathname]);

	return (
		<div
			className={"common-page-container " + (isIOS13() ? 'iphone-fix' : '')}
			onTouchStart={touchStart(0)}
			onTouchMove={touchMove}
			onTouchEnd={touchEnd(history, 'help-example', 'help-order-status-work')}>
			<TopBar backUrl={'/help-order-status-work'} />
			<Row
				justify="space-around"
				align="middle"
				className="help-example"
				style={{ left: `${currentPageX - pagex}px` }}>
				<Col span={23} className="col-container">
					{lang === 'fr' && (
						<Row>
							<Col span={24}>
								<Image preview={false} src="/media/svg/help_example.svg" />
							</Col>
						</Row>
					)}
					{lang === 'en' && (
						<Row>
							<Col span={24}>
								<Image preview={false} src="/media/svg/onboarding5.svg" />
							</Col>
						</Row>
					)}
					<Row>
						<Col span={24}>
							<Typography.Title level={5} className="order-status-title">
								{t('forExample')}
							</Typography.Title>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Typography.Paragraph
								level={5}
								className="order-status-description-1">
								The order’s status is set to <b>Needs Attention</b> because a line item is <b>Backordered</b>. A progress bar will update as the status of your order changes to indicate the number of line items that are <b>Completed</b>.
							</Typography.Paragraph>
						</Col>
					</Row>
					<Row>
						<Col span={4} offset={10}>
							<div className="inactive-bullet bullet" />
							<div className="inactive-bullet bullet" />
							<div className="active-bullet bullet" />
						</Col>
					</Row>
					<Row className='button-container'>
						<Col span={24}>
							<Button
								className="common-active-button"
								onClick={() => {
									push('/help');
								}}>
								{t('backToSupport')}
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};
