import {} from 'react';
import { Card, Col, Image, Row, Typography, Tag, Tooltip } from 'antd';
import { LINE_ITEM_STATU_MAP } from '../types/enums';
import moment from 'moment';
import { getLocaleCurrencyFormat } from '../utils/localCurrencyFormat';
import { t } from 'i18next';
import { getFilterIcon } from './LineFilterButton';

const getDelivertDate = entry => {
	if (entry.itemStatus === 'Completed') {
		return (
			<Typography.Text className="line-item-info">
				{t('delivered')} :
				{' ' + entry.deliveryDate
					? moment(entry.deliveryDate).format('L')
					: '-'}
			</Typography.Text>
		);
	} else if(entry.itemStatus === LINE_ITEM_STATU_MAP.Shipped){
		if(entry.deliveryDate){
			return (
				<Typography.Text className="line-item-info">
					{t('delivered')} :
					{' ' + entry.deliveryDate
						? moment(entry.deliveryDate).format('L')
						: '-'}
				</Typography.Text>
			);
		}else{
			return (
				<Typography.Text className="line-item-info">
					{t('estDelivery')} :
					{' ' + entry.estDeliveryDate
						? moment(entry.estDeliveryDate).format('L')
						: '-'}
				</Typography.Text>
			);
		}
	} else if (
		entry.itemStatus !== 'Canceled' &&
		entry.itemStatus !== 'Submitted'
	) {
		return (
			<Typography.Text className="line-item-info">
				{t('estDelivery')} :
				{' ' + entry.estDeliveryDate
					? moment(entry.estDeliveryDate).format('L')
					: '-'}
			</Typography.Text>
		);
	} else {
		return '';
	}
};

const LineItem = ({ entry, isLoading, imageUrl }) => {
	return (
		<Col span={24} key={'li-' + entry.productCode}>
			<Card className="line-item" loading={isLoading}>
				<Row>
					<Col span={7}>
						<Row>
							<Col>
								<Image
									className="line-item-img"
									src={
										imageUrl ? imageUrl : '/media/missing-product-515x515.jpg'
									}
								/>
							</Col>
						</Row>
						<Row>
							<Col offset={8} span={5}>
								<Image src={getFilterIcon('', entry.itemStatus)} />
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Typography.Text
									className="line-item-info" style={{fontSize: '0.75rem'}}>
									{t(LINE_ITEM_STATU_MAP[entry.itemStatus])}
								</Typography.Text>
							</Col>
						</Row>
					</Col>
					<Col span={17}>
						<Row>
							<Col span={24}>
								<Typography.Title className="line-item-name">
									{entry.productName}
								</Typography.Title>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Typography.Text className="line-item-info">
									{entry.productCode}
								</Typography.Text>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Typography.Text className="line-item-info">
									{t('qty')}: {Math.round(entry.productQuantity)}
								</Typography.Text>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Typography.Text className="line-item-info">
									{entry.lineTotal
										? getLocaleCurrencyFormat(
												entry.currencyCode,
												entry.lineTotal,
										  )
										: 0}{' '}
									{t('total')}
								</Typography.Text>
							</Col>
						</Row>
						{
							(entry.itemStatus !== LINE_ITEM_STATU_MAP.Canceled && entry.deliveryDate !== null || entry.estDeliveryDate !== null) && (<Row>
								<Col span={24}>{getDelivertDate(entry)}</Col>
							</Row>)
						}
						{entry.trackingNum !== null && (
							<Row>
								<Col span={24}>
									{entry.trackingNum && entry.trackingNum.length > 0 && (
										<Typography.Text className="line-item-info tacking-number">
											Tracking Number/s:{' '}
											{entry.trackingNum.map((tr, index) => (
												<Tooltip key={'tt-' + index} title="Click text to copy">
													<Tag
														style={{ cursor: 'pointer' }}
														title="Click to copy"
														key={'tr-' + index}
														onClick={() => {
															navigator.clipboard.writeText(tr);
														}}>
														{tr}
													</Tag>
												</Tooltip>
											))}
										</Typography.Text>
									)}
								</Col>
							</Row>
						)}
					</Col>
				</Row>
			</Card>
		</Col>
	);
};
export default LineItem;
