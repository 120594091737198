import { TopBar } from '../elements/TopBar';
import '../styles/p_profile.scss';
import '../styles/info.scss';
import { Footer } from '../elements/Footer';
import { useTranslation } from 'react-i18next';
import { BASE_TITLE, getFsrEmail, getFsrName, getFsrNumber } from '../utils/utility';
import { useEffect } from 'react';

function Profile() {
	const { t } = useTranslation();

	// sets page title
	useEffect(() => {
		document.title = BASE_TITLE + ' - Profile';
	}, []);
	return (
		<div>
			<div className="profile">
				<div className="topbar">
					<TopBar backVisibility={false} />
				</div>
				<div className="profile-info">
					<div className="name">{getFsrName()}</div>
					{/* <div className="member-group">{t('placeOrder')}</div> */}
					<div className="info-list">
						<div className="info-title">{t('contactInformation')}</div>
						<div className="info-container">
							<div className="desc">
								<div className="desc-title">{t('email')}</div>
								<div className="desc-value">{getFsrEmail()}</div>
								<div className="separator" />
							</div>
							<div className="desc">
								<div className="desc-title">{t('notificationEmail')}</div>
								<div className="desc-value">{getFsrEmail()}</div>
								<div className="separator" />
							</div>
							<div className="desc">
								<div className="desc-title">{t('phoneNo')}</div>
								<div className="desc-value">{getFsrNumber()}</div>
								<div className="separator" />
							</div>
							<div className="desc">
								<div className="desc-title">{t('mobileNo')}</div>
								<div className="desc-value">{getFsrNumber()}</div>
								<div className="separator" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Profile;
