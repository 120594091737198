import { useEffect, useState } from 'react';
import '../styles/information_go.scss';
import { BASE_TITLE, returnSvg } from '../utils/utility';
import { useHistory } from 'react-router';
import { touchEnd, touchStart, touchMove } from '../utils/touchfunctionality';
import { useTranslation } from 'react-i18next';
import { Image } from 'antd';
import { OBTopBar } from '../elements/OBTopBar';

function OrderStatus() {
	const [pageIndex] = useState(1);
	const history = useHistory();
	const { t } = useTranslation();

	// sets page title
	useEffect(() => {
		document.title = BASE_TITLE + ' - Onboarding - 4';
	}, []);
	return (
		<div
			className="onboarding-container"
			onTouchStart={touchStart(0)}
			onTouchMove={touchMove}
			onTouchEnd={touchEnd(history, 'onboarding3', 'onboarding1')}>
			<OBTopBar />
			<div className="img-container img-container2">
				<Image preview={false} src="/media/svg/onboarding2.svg" />
			</div>
			<div className="description">
				<div className="title-text">{t('dataRefresh')}</div>
				<br />
				<div className="body-text">{t('infoApp')}</div>
			</div>
			<div className="pagination-list">
				{[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) =>
					returnSvg(Boolean(pageIndex === index)),
				)}
			</div>
			<div className="button-container button-container-flex">
				<div
					className="button skip-all"
					onClick={() => history.push('/accounts/updates')}>
					{t('skipAll')}
				</div>
				<div
					className="button next"
					onClick={() => {
						history.push('/onboarding3');
					}}>
					{t('next')}
				</div>
			</div>
		</div>
	);
}

export default OrderStatus;
