import { useEffect } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';
import { handleErrors } from './services/HttpService';
import InformationGo from './pages/InformationGoOnboard1';
import OrderLineItemExplanationOnboard5 from './pages/OrderLineItemExplanationOnboard5';
import { AccountList } from './pages/AccountList';
import { AccountOrders } from './pages/AccountOrders';
import { Help } from './pages/Help';
import { HelpExample } from './pages/HelpExample';
import { HelpLineItems } from './pages/HelpLineItems';
import { HelpOrderStatus } from './pages/HelpOrderStatus';
import { HelpOrderStatusWork } from './pages/HelpOrderStatusWork';
import LoginScreen from './pages/LoginScreen';
import { OrderHead } from './pages/OrderHead';
import { OrderIndex } from './pages/OrderIndex';
import OrderStatus from './pages/OrderStatusOnboard2';
import IconExplanationOnboard from './pages/IconExplanationOnboard3';
import OrderExplanationOnboard from './pages/OrderExplanationOnboard4';
import Onboarding6 from './pages/Onboarding6';
import Onboarding7 from './pages/Onboarding7';
import Onboarding8 from './pages/Onboarding8';
import Profile from './pages/Profile';
import Dashboard from './pages/Dashboard';
import { ProductSubstitution } from './pages/ProductSubstitution';
import { useIdleTimer } from 'react-idle-timer';
import { isLoggedIn, showLogin } from './services/AuthService';
import { Modal } from 'antd';
import { PortraitModeOnly } from './elements/PortraitModeOnly';

function App() {
	const { getRemainingTime, getLastActiveTime } = useIdleTimer({
		timeout: 1000 * 60 * 15,
		onIdle: () => {
			sessionStorage.removeItem('fsrToken');
			Modal.warn({
				title: 'Session Expired',
				content: 'Your session is expired. Redirecting to login!',
				onOk: () => {
					showLogin();
				},
				onCancel: () => {
					showLogin();
				},
			});
		},
		debounce: 500,
	});
	console.log(getRemainingTime, getLastActiveTime);

	useEffect(() => {
		if (!isLoggedIn() && window.location.pathname !== '/') {
			showLogin();
		}

		screen !== undefined &&
			screen.orientation !== undefined &&
			screen.orientation.addEventListener('change', () => {
				rotate();
			});
	}, []);

	function fullScreenCheck() {
		if (document.fullscreenElement) return;
		return document.documentElement.requestFullscreen();
	}
	async function rotate() {
		try {
			await fullScreenCheck();
		} catch (err) {
			handleErrors(err);
		}
		// await screen.orientation.lock('portrait');
	}

	return (
		<PortraitModeOnly>
			<Router>
				<Switch>
					<Route path="/" exact={true}>
						<LoginScreen />
					</Route>
					<Route path="/" exact={true}>
						<Redirect to="login" />
					</Route>
					<Route path="/login">
						<LoginScreen />
					</Route>
					<Route path="/home">
						<Dashboard />
					</Route>
					<Route path="/profile">
						<Profile />
					</Route>
					<Route path="/help">
						<Help />
					</Route>
					<Route path="/help-order-status">
						<HelpOrderStatus />
					</Route>
					<Route path="/help-order-status-work">
						<HelpOrderStatusWork />
					</Route>
					<Route path="/help-example">
						<HelpExample />
					</Route>
					<Route path="/help-line-items">
						<HelpLineItems />
					</Route>
					<Route path="/accounts/updates">
						<AccountList hasUpdates={true} />
					</Route>
					<Route path="/accounts">
						<AccountList />
					</Route>
					<Route path="/account/:accountId/orders">
						<AccountOrders />
					</Route>
					<Route path="/account/:accountId/:cac/order/:orderId/line-items">
						<OrderHead />
					</Route>
					<Route path="/orders">
						<OrderIndex />
					</Route>
					<Route path="/onboarding1">
						<InformationGo />
					</Route>
					<Route path="/onboarding2">
						<OrderStatus />
					</Route>
					<Route path="/onboarding3">
						<IconExplanationOnboard />
					</Route>
					<Route path="/onboarding4">
						<OrderExplanationOnboard />
					</Route>
					<Route path="/onboarding5">
						<OrderLineItemExplanationOnboard5 />
					</Route>
					<Route path="/onboarding6">
						<Onboarding6 />
					</Route>
					<Route path="/onboarding7">
						<Onboarding7 />
					</Route>
					<Route path="/onboarding8">
						<Onboarding8 />
					</Route>
					<Route path="/profile">
						<Profile />
					</Route>
					<Route path="/profile">
						<Profile />
					</Route>
					<Route path="/dashboard">
						<Dashboard />
					</Route>
					<Route path="/order/:orderId/product-substitution/:lineItemId">
						<ProductSubstitution />
					</Route>
				</Switch>
			</Router>
		</PortraitModeOnly>
	);
}

export default App;
