// constants of order status
export const COMPOUND_ORDER_STATUS = {
	ALL: 'ALL',
	NEED_ATTENTION: 'NEED_ATTENTION',
	IN_PROGRESS: 'IN_PROGRESS',
	COMPLETED: 'COMPLETED',
};

// order status to order status text mapping
export const ORDER_STATS_TEXT = {
	ALL: 'All',
	NEED_ATTENTION: 'Need Attention',
	IN_PROGRESS: 'In Progress',
	COMPLETED: 'Completed',
};

// order status return from orders
export const ORDER_API_STATUS = {
	BACKORDERED: 'Backordered',
	CANCELLED: 'Canceled',
	IN_PROGRESS: 'InProgress',
	SUBMITTED: 'Submitted',
	SHIPPED: 'Shipped',
	COMPLETE: 'Completed',
	ALL: 'ALL',
};

// mapping text for api status to viewable
export const ORDER_API_STATUS_TEXT = {
	BACKORDERED: 'Backordered',
	CANCELLED: 'Canceled',
	IN_PROGRESS: 'In Progress',
	SUBMITTED: 'Submitted',
	SHIPPED: 'Shipped',
	COMPLETE: 'Completed',
	ALL: 'All',
};

// Line item statuses
export const LINE_ITEM_STATU_MAP = {
	Backordered: 'Backordered',
	Canceled: 'Canceled',
	In_progress: 'In Progress',
	'In Progress': 'In Progress',
	Submitted: 'Submitted',
	Shipped: 'Shipped',
	Complete: 'Complete',
	Completed: 'Completed',
};

export const FSR_ORDER_STATUS = {
	INPROGRESS: 'In Progress',
	NEEDATTENTION: 'Needs Attention',
	COMPLETED: 'Completed',
	ALL: 'All',
};

// call center number of countries
export const COUNTRY_TO_CCNUMBER = {
	UK: '1-866-565-4283',
	FR: '+33155002475',
	CH: '0800830085',
	US: '1-866-565-4283'
};

// country flags map
export const COUNTRY_TO_FLAG = {
	UK: 'uk-flag.png',
	FR: 'fr-flag.png',
	CH: 'ch-flag.png',
	US: 'us-flag.svg'
};
