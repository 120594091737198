import { getUrl } from './HttpService';
import { refreshToken } from './AuthService';

export const getSubstituteProducts = ($code, $country) => {
	let queryParams = {
		productCode: $code
	};
	const url = getUrl(
		process.env.REACT_APP_PS_API,
		queryParams,
	);
	return fetch(url, {
		'Content-Type': 'application/json',
		// headers: {
		// 	Authorization:
		// 		'Bearer ' + JSON.parse(sessionStorage.getItem('fsrToken')).access_token,
		// },
	}).then(response => {
		const data = response.json();
		if (data.error && data.error === 'Token has been revoked.') {
			return refreshToken().then(() => getSubstituteProducts($code, $country));
		}
		return data;
	});
};
