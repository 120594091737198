import { Row, Col, Typography, Image, Button } from 'antd';
import './../styles/HelpLineItems.scss';
import { TopBar } from '../elements/TopBar';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useState } from 'react';
import { BASE_TITLE } from '../utils/utility';
import { isIOS13 } from './HelpExample';

export const HelpLineItems = () => {
	const { push } = useHistory();
	const { t } = useTranslation();
	const [lang] = useState(localStorage.getItem('lang'));

	// sets page title
	useEffect(() => {
		localStorage.setItem('backUrl', '/help');
		document.title = BASE_TITLE + ' - Help - Line Items';
	}, []);

	return (
		<div
			className={
				'common-page-comtainer ' +
				(isIOS13() > -1
					? 'iphone-fix'
					: '')
			}>
			<TopBar backUrl={'/help'} />
			<Row justify="space-around" align="middle" className="help-line-items">
				<Col span={23} className="col-container">
					<Row>
						<Col span={24}>
							{lang === 'en' && (
								<Image
									className="help-image"
									src="/media/svg/onboarding6.svg"
									preview={false}
								/>
							)}
							{lang === 'fr' && (
								<Image preview={false} src="/media/svg/onboarding6.svg" />
							)}
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Typography.Title level={5} className="order-status-title">
								{t('ordeLineItemStatus')}
							</Typography.Title>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Typography.Paragraph
								level={5}
								className="order-status-description-1">
								{t('orderBrokenLineItems')}
							</Typography.Paragraph>
						</Col>
					</Row>
					<Row className="button-container">
						<Col span={24}>
							<Button
								className="common-active-button action-button"
								onClick={() => {
									push('/help');
								}}>
								{t('backToSupport')}
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};
