import { Card, Row, Col, Typography, Image, Progress } from 'antd';
import './../styles/order.scss';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getOrderStatusIcon } from '../utils/utility';
import { getLocaleCurrencyFormat } from '../utils/localCurrencyFormat';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';

/*
Returns a single order for order info and loading info
*/
export const Order = ({
	order,
	loading,
	withNavigation,
	accountId,
	orderHead = false,
	allOrders = false,
}) => {
	const { t } = useTranslation();
	const { push } = useHistory();
	// const [expanded, setExpand] = useState(
	// 	localStorage.getItem('expand') === null ||
	// 		localStorage.getItem('expand') === 'true',
	// );
	const [expanded, setExpand] = useState(false);
	return (
		<Col span={24}>
			<Card
				className="order-item"
				loading={loading}
				onClick={event => {
					if (event.target.classList.contains('order-item-account-info')) {
						return false;
					}
					if (!orderHead) {
						push(
							`/account/${order.accountId}/${accountId}/order/${order.orderRecordId}/line-items`,
							{
								backUrl: window.location.pathname,
							},
						);
					}
				}}>
				<Row>
					<Col span={20}>
						<Row>
							<Col span={24}>
								<Typography.Title level={5} className="order-item-order-no">
									{t('order')} #{order.orderNum}
								</Typography.Title>
							</Col>
						</Row>
						{(expanded || !orderHead) && (
							<>
								<Row>
									<Col span={24}>
										<Typography.Text
											className="order-item-account-info"
											onClick={() => {
												if (orderHead) {
													push(
														`/account/${
															accountId === undefined
																? order.accountId
																: accountId
														}/orders`,
														{
															backUrl: window.location.pathname,
														},
													);
												} else if (allOrders) {
													push(`/account/${accountId}/orders`, {
														backUrl: window.location.pathname,
													});
												} else {
													push(
														`/account/${order.accountId}/${accountId}/order/${order.orderRecordId}/line-items`,
														{
															backUrl: window.location.pathname,
														},
													);
												}
											}}>
											{order.accountName} (#{order.accountId})
										</Typography.Text>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<Typography.Text className="order-item-order-detail">
											PO #{order.poNumber}
										</Typography.Text>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<div className="space-maker" />
										<Typography.Text className="order-item-order-detail">
											{t('orderDate')}:{' '}
											{order.requestedDate
												? moment(order.requestedDate).format('L')
												: ''}
										</Typography.Text>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<Typography.Text className="order-item-order-detail">
											{t('total')}:{' '}
											{order.currencyCode !== null ? getLocaleCurrencyFormat(
												order.currencyCode,
												(Math.round(order.totalAmount * 100) / 100),
											): getLocaleCurrencyFormat(
												'USD',
												(Math.round(order.totalAmount * 100) / 100),
											)}
										</Typography.Text>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<div className="space-maker" />
										{order.updatedDate !== null && (
											<Typography.Text className="order-item-order-detail updated-date">
												{t('updatedDate')}:{' '}
												{order.updatedDate
													? moment(order.updatedDate).format('L')
													: ''}
											</Typography.Text>
										)}
										{order.updatedDate === null && (
											<Typography.Text className="order-item-order-detail updated-date">
												{t('noUpdates')}
											</Typography.Text>
										)}
									</Col>
								</Row>
								<Row>
									<Col span={14}>
										<Progress percent={order.prcntComplete} showInfo={false} />
									</Col>
									<Col span={10}>
										<Typography.Text className="order-item-order-detail">
											&nbsp;
											{parseInt(order.prcntComplete)}%
										</Typography.Text>
										<Typography.Text className="order-item-order-detail">
											&nbsp;{t('complete')}
										</Typography.Text>
									</Col>
								</Row>
							</>
						)}
					</Col>
					<Col span={3} offset={1} className="icon-container">
						{order.orderStatus != null && getOrderStatusIcon(order.orderStatus)}
						{withNavigation && (
							<div className="chavlon-icon">
								<Image preview={false} src="/icons/chavlon.png" />
							</div>
						)}

						{expanded && orderHead && (
							<MinusCircleOutlined
								onClick={() => {
									setExpand(false);
									localStorage.setItem('expand', 'false');
								}}
							/>
						)}
						{!expanded && orderHead && (
							<PlusCircleOutlined
								size={20}
								onClick={() => {
									setExpand(true);
									localStorage.setItem('expand', 'true');
								}}
							/>
						)}
					</Col>
				</Row>
			</Card>
		</Col>
	);
};
