import { Card, Row, Col, Typography, Empty, Breadcrumb } from 'antd';
import { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation, Link } from 'react-router-dom';
import { getOrders } from '../services/accountOrders';
import '../styles/AccountOrders.scss';
import { FSR_ORDER_STATUS } from '../types/enums';
import { Footer } from '../elements/Footer';
import { TopBar } from '../elements/TopBar';
import { useTranslation } from 'react-i18next';
import CircularProgressBar from '../elements/CircularProgressBar';
import { Order } from '../elements/Order';
import { OrderFilter } from '../elements/OrderFilter';
import { AppSearch } from '../elements/AppSearch';
import { touchEnd, touchStart, touchMove } from '../utils/touchfunctionality';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { BASE_TITLE } from '../utils/utility';

const initialMeta = {
	ALL: 0,
	[FSR_ORDER_STATUS.COMPLETED]: 0,
	[FSR_ORDER_STATUS.NEEDATTENTION]: 0,
	[FSR_ORDER_STATUS.INPROGRESS]: 0,
};

// returns count of orders related to active filter
const getActiveFilterCount = (activeFilter, meta) => {
	if (meta[activeFilter]) {
		return meta[activeFilter];
	} else {
		return meta['ALL'];
	}
};

const { Item } = Breadcrumb;

// Return Account orders screen
export const AccountOrders = () => {
	// define the loading text
	const loadingLiterals = 'Loading...';
	const history = useHistory();

	const { state } = useLocation();

	// get/set back url
	const [backUrl, setBackUrl] = useState(
		state !== undefined ? state.backUrl : localStorage.getItem('backUrl'),
	);

	// set and hold screen's loading status
	const [loading, setLoading] = useState(false);

	// set and hold application's init status
	const [init, setInit] = useState(false);

	// initiate the account id parameter passed through url
	const { accountId } = useParams();

	// set and hold whether reset the orders list when receiving the next set of orders from the API
	const [reset, setRest] = useState(true);

	// if mustSearch only true the API call to load orders is calling
	const [mustSearch, setMustSearch] = useState(true);

	// set and keep account details required by the screen
	const [accountDetails, setAccountDetails] = useState({
		accountName: loadingLiterals,
		accountId: loadingLiterals,
		accountAddress: loadingLiterals,
	});

	// set and hold displaying orders
	const [orders, setOrders] = useState([]);

	// set and hold seatch text
	const [searchText, setSearchText] = useState('');

	// init the internationalization
	const { t } = useTranslation();

	// set and keep meta info, counts against order statuses
	const [meta, setMeta] = useState(initialMeta);

	// set and have active filter, initial value is set to ALL
	const [activeFilter, setActiveFilter] = useState(FSR_ORDER_STATUS.ALL);

	// set and hold current page number which should be string
	const [page, setPage] = useState('0');

	// sets page title
	useEffect(() => {
		document.title = BASE_TITLE + ' - Account Head';
	}, []);
	const [expanded, setExpand] = useState(false);

	let bUrl = state ? state.backUrl : '';
	useEffect(() => {
		if (state && state.backUrl) {
			setBackUrl(state.backUrl);
			localStorage.setItem('backUrl', state.backUrl);
			localStorage.setItem('AOBackUrl', state.backUrl);
		} else {
			localStorage.setItem('backUrl', '/orders');
		}
	}, [bUrl]);

	// change active filter, which should trigger an API call to obtain next set of orders
	const setFilter = filterName => {
		if (filterName === activeFilter) {
			return;
		}
		setOrders([]);
		setPage('0');
		setActiveFilter(filterName);
		setRest(true);
		setMustSearch(true);
	};

	// limit of the number of orders per page obtain through API
	const limit = 10;

	// API wrapper to call load orders for given parameters
	const loadOrders = () => {
		// if loading true return without processing further since an active API call is executing to load orders
		if (loading) {
			return;
		}

		// set loadingto true before execute API call
		setLoading(true);

		// defines the parameters to passed to the API as a json object
		const params = {
			limit: limit.toString(),
			offset: page,
			orderStatus: activeFilter,
			searchParam: searchText,
			accNo: accountId,
		};

		// if active filter is ALL remove order status parameter, so that orders will all statuses will be received
		if (activeFilter === FSR_ORDER_STATUS.ALL) {
			delete params.orderStatus;
		}

		// this is the actual service call with the prameters
		getOrders(params)
			.then(response => {
				// if response is empty or have error there is nothing to process further
				if (response.length === 0 || response.error) {
					setLoading(false);
					return;
				}

				// update the meta which are the counts of orders against each type of order statuses
				const pageMeta = response.shift().meta.order_summary;
				if (pageMeta !== null) {
					setMeta(pageMeta);
				}

				// process the orders and convert into UI compatible json array
				const newOrders = response[0].account_orders.map(order => {
					return {
						orderNum: order.order_number.split('-')[0],
						accountName: order.shitpto_cus_name,
						accountId: order.cus_shipto_num,
						poNumber: order.po_number,
						requestedDate: order.order_placement_date,
						totalAmount: order.total_price,
						currencyCode: order.currency,
						updatedDate: order.order_last_update,
						prcntComplete: order.ordr_prcnt_complete,
						orderStatus: order.order_status,
						orderRecordId: order.order_number,
					};
				});

				// account details are set only once after screen is oaded after we do not refresh since,
				// accout details does not depend on any filter, search param or a page
				if (!init) {
					setAccountDetails({
						accountName: response[0].cac_account_name,
						accountId: response[0].cac_account_number,
						accountAddress: response[0].cac_account_address,
					});
				}

				// if reset requirded the API return orders a directly shown otherwise append the new orders to existing list
				setOrders(reset ? newOrders : orders.concat(newOrders));

				// update init progress is completed
				setInit(true);

				// finised api call and processing
				setLoading(false);
			})

			// Error handling
			.catch(() => {
				setMeta(initialMeta);
				if(localStorage.getItem('activeAccount') !== null){
					const activeAccount = JSON.parse(localStorage.getItem('activeAccount'));
					setAccountDetails({
						accountName: activeAccount.cac_account_name,
						accountId: activeAccount.cac_account_number,
						accountAddress: activeAccount.cac_account_address,
					});
				}
				setLoading(false);
			});
	};

	useEffect(() => {
		if (mustSearch) {
			setMustSearch(false);
			loadOrders();
		}
	}, [activeFilter, page, mustSearch, searchText]);

	const initSearch = () => {
		document.activeElement.blur();
		setOrders([]);
		setPage('0');
		setRest(true);
		setMustSearch(true);
		window.focus();
		document.activeElement.blur();
	};

	const mapOrder = (order, index) => (
		<Order
			key={index}
			order={order}
			loading={loading && !init}
			withNavigation
			accountId={accountDetails.accountId}
		/>
	);

	return (
		<div
			className="common-page-comtainer"
			onTouchStart={touchStart(0)}
			onTouchMove={touchMove}
			onTouchEnd={touchEnd(history, null, null, true)}>
			<TopBar backUrl={backUrl} />
			<Row>
				<Col span={22} offset={1}>
					<Breadcrumb>
						{localStorage.getItem('backUrl') === '/orders' && (
							<Item key={'bc-orders'}>
								<Link className="disabled" to={'/orders'}>
									{t('orders')}
								</Link>
							</Item>
						)}
						{localStorage.getItem('backUrl') !== '/orders' && (
							<Item key={'bc-accounts'}>
								<Link className="disabled" to={'/accounts/updates'}>
									{t('accounts')}
								</Link>
							</Item>
						)}
						<Item key={'bc-aorders'}>
							<Link to={window.location.pathname}>{t('account')}</Link>
						</Item>
					</Breadcrumb>
				</Col>
			</Row>
			<Row justify="space-around" align="middle" className={"account-orders"}>
				<Col span={23} className="col-container">
					<Row justify="space-around" align="middle" className="accinfo-height">
						<Col span={23}>
							<Card
								className="account-orders-top-container"
								loading={loading && !init}>
								<Typography.Title level={5} className="account-name">
									{accountDetails.accountName}
								</Typography.Title>
								{expanded && (
									<MinusCircleOutlined
										onClick={() => {
											setExpand(false);
											localStorage.setItem('expand', 'false');
										}}
										className="expander"
									/>
								)}
								{!expanded && (
									<PlusCircleOutlined
										size={20}
										onClick={() => {
											setExpand(true);
											localStorage.setItem('expand', 'true');
										}}
										className="expander"
									/>
								)}
								{expanded && (
									<>
										<Typography.Text>
											{t('account')} #{accountDetails.accountId}
										</Typography.Text>
										<br />
										<Typography.Text>
											{t('address')} {accountDetails.accountAddress}
										</Typography.Text>
									</>
								)}
							</Card>
						</Col>
					</Row>
					<Row
						justify="space-around"
						align="middle"
						className="main-topic-height">
						<Col span={24}>
							<Typography.Title level={5} className="page-header-text">
								{t('orders')}
							</Typography.Title>
						</Col>
					</Row>
					<Row className="search-height">
						<Col span={24}>
							<AppSearch
								placeholder={t('searchOrder')}
								onChange={event => {
									setSearchText(event.target.value);
									if (event.target.value === '') {
										initSearch();
									}
								}}
								onPressSearch={() => {
									initSearch();
								}}
								onPressEnter={() => {
									initSearch();
								}}
								onKeyPress={e => {
									if (e.key === 'Enter') {
										document.activeElement.blur();
									}
								}}
							/>
						</Col>
					</Row>
					<Row align="middle" className="filter-buttons-height">
						<Col span={6}>
							<OrderFilter
								onClick={() => {
									setFilter(FSR_ORDER_STATUS.ALL);
								}}
								text={meta.ALL}
								activeFilter={activeFilter}
								filter={FSR_ORDER_STATUS.ALL}
							/>
						</Col>
						<Col span={6}>
							<OrderFilter
								onClick={() => {
									setFilter(FSR_ORDER_STATUS.NEEDATTENTION);
								}}
								text={meta[FSR_ORDER_STATUS.NEEDATTENTION]}
								activeFilter={activeFilter}
								filter={FSR_ORDER_STATUS.NEEDATTENTION}
							/>
						</Col>
						<Col span={6}>
							<OrderFilter
								onClick={() => {
									setFilter(FSR_ORDER_STATUS.INPROGRESS);
								}}
								text={meta[FSR_ORDER_STATUS.INPROGRESS]}
								activeFilter={activeFilter}
								filter={FSR_ORDER_STATUS.INPROGRESS}
							/>
						</Col>
						<Col span={6}>
							<OrderFilter
								onClick={() => {
									setFilter(FSR_ORDER_STATUS.COMPLETED);
								}}
								text={meta[FSR_ORDER_STATUS.COMPLETED]}
								activeFilter={activeFilter}
								filter={FSR_ORDER_STATUS.COMPLETED}
							/>
						</Col>
					</Row>
					<Row
						justify="space-around"
						align="middle"
						className="sub-topic-height">
						<Col span={24}>
							<Typography.Title className="filter-name-text" level={5}>
								{t(activeFilter)}
							</Typography.Title>
						</Col>
					</Row>
					<Row
						justify="space-around"
						align="middle"
						className="order-container"
						onScroll={e => {
							const bottom =
								Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 10;
							if (
								bottom &&
								orders.length > 0 &&
								loading <= 0 &&
								orders.length < getActiveFilterCount(activeFilter, meta)
							) {
								setPage(`${Number(page) + 1}`);
								setRest(false);
								setMustSearch(true);
							}
						}}>
						{orders
							.filter(
								order => order.orderStatus === FSR_ORDER_STATUS.NEEDATTENTION,
							)
							.map((order, index) =>
								mapOrder(order, 'need-attention-' + index.toString()),
							)}
						{orders
							.filter(
								order => order.orderStatus === FSR_ORDER_STATUS.INPROGRESS,
							)
							.map((order, index) =>
								mapOrder(order, 'inprogress-' + index.toString()),
							)}
						{orders
							.filter(order => order.orderStatus === FSR_ORDER_STATUS.COMPLETED)
							.map((order, index) =>
								mapOrder(order, 'completed-' + index.toString()),
							)}
						{activeFilter === FSR_ORDER_STATUS.ALL &&
							orders
								.filter(order => {
									return order.orderStatus === null || order.orderStatus === "";
								})
								.map((order, index) =>
									mapOrder(order, 'unknown-' + index.toString()),
								)}
						{orders.length === 0 && !loading && <Empty />}
						{loading && (
							<CircularProgressBar
								percentage={10}
								status="exception"
								width={50}
							/>
						)}
					</Row>
				</Col>
			</Row>
			<Footer />
		</div>
	);
};
