import { useEffect, useState } from 'react';
import '../styles/information_go.scss';
import { BASE_TITLE, returnSvg } from '../utils/utility';
import { useHistory } from 'react-router';
import { touchEnd, touchStart, touchMove } from '../utils/touchfunctionality';
import { useTranslation } from 'react-i18next';
import { Image } from 'antd';
import { OBTopBar } from '../elements/OBTopBar';

function Onboarding7() {
	const [pageIndex] = useState(6);
	const history = useHistory();
	const { t } = useTranslation();
	const [lang] = useState(localStorage.getItem('lang'));

	// sets page title
	useEffect(() => {
		document.title = BASE_TITLE + ' - Onboarding - 7';
	}, []);
	return (
		<div
			className="onboarding-container text-overflow-auto"
			onTouchStart={touchStart(0)}
			onTouchMove={touchMove}
			onTouchEnd={touchEnd(history, 'onboarding8', 'onboarding6')}>
			<OBTopBar />
			{lang === 'en' && (
				<div className="img-container img-container7">
					<Image preview={false} src="/media/svg/onboarding7.svg" />
				</div>
			)}
			<div className="description description7">
				<div className="title-text">{t('seeWebApp')}</div>
				<div className="body-text">{t('seeWebAppBrowser')}</div>
			</div>
			<div className="pagination-list">
				{[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) =>
					returnSvg(Boolean(pageIndex === index)),
				)}
			</div>
			<div
				className="button-container button-container-flex"
				style={{ top: '87%' }}>
				<div
					className="button skip-all"
					onClick={() => {
						localStorage.setItem('onboarded', '1');
						history.push('/accounts/updates');
					}}>
					{t('skipAll')}
				</div>
				<div
					className="button next"
					onClick={() => {
						history.push('/onboarding8');
					}}>
					{t('next')}
				</div>
			</div>
		</div>
	);
}

export default Onboarding7;
