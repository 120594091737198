import { Button, Image } from 'antd';
import { t } from 'i18next';
import { ORDER_API_STATUS_TEXT } from '../types/enums';

const getFilterClass = (activeFilter, filter) =>
	activeFilter === filter ? `active-filter filter ${filter}-selected` : 'inactive-filter filter';
export const getFilterButtonText = (meta, filter) => {
	if (filter === 'All') {
		return meta && meta['ALL'] ? (meta['ALL'] > 99 ? '99+': meta['ALL']) : 0;
	} else if(filter === 'In Progress') {
		return meta['In Progress'] ? (meta['In Progress'] > 99 ? '99+': meta['In Progress']) : 0;
	} else {
		return meta[filter] ? (meta[filter] > 99 ? '99+': meta[filter]) : 0;
	}
};
const getInactiveImage = filter => {
	switch (filter) {
		case ORDER_API_STATUS_TEXT.ALL:
			return '/icons/all-dark.svg';
		case ORDER_API_STATUS_TEXT.BACKORDERED:
			return '/icons/backorder-dark.svg';
		case ORDER_API_STATUS_TEXT.CANCELLED:
			return '/icons/cancel-dark.svg';
		case ORDER_API_STATUS_TEXT.IN_PROGRESS:
			return '/icons/progress-dark.svg';
		case ORDER_API_STATUS_TEXT.SHIPPED:
			return '/icons/ship-dark.svg';
		case ORDER_API_STATUS_TEXT.SUBMITTED:
			return '/icons/submit-dark.svg';
		case ORDER_API_STATUS_TEXT.COMPLETE:
			return '/icons/filter-completed.png';
		default:
			return '/icons/all-dark.svg';
	}
};
const getActiveImage = filter => {
	switch (filter) {
		case ORDER_API_STATUS_TEXT.ALL:
			return '/icons/all-light.svg';
		case ORDER_API_STATUS_TEXT.BACKORDERED:
			return '/icons/backorder-light.svg';
		case ORDER_API_STATUS_TEXT.CANCELLED:
			return '/icons/cancel-light.svg';
		case ORDER_API_STATUS_TEXT.IN_PROGRESS:
			return '/icons/progress-light.svg';
		case ORDER_API_STATUS_TEXT.SHIPPED:
			return '/icons/ship-light.svg';
		case ORDER_API_STATUS_TEXT.SUBMITTED:
			return '/icons/submit-light.svg';
		case ORDER_API_STATUS_TEXT.COMPLETE:
			return '/icons/filter-done-inactive.png';
		default:
			return '/icons/all-light.svg';
	}
};
export const getFilterIcon = (activeFilter, filter) => {
	if (activeFilter === filter) {
		return getActiveImage(filter);
	} else {
		return getInactiveImage(filter);
	}
};

const LineFilterButton = ({ onClick, activeFilter, filter, meta }) => (
	<Button
		icon={
			<Image
				className="filter-button-icon"
				preview={false}
				src={getFilterIcon(activeFilter, filter)}
			/>
		}
		onClick={onClick}
		className={getFilterClass(activeFilter, filter)}>
		&nbsp;&nbsp;{t(filter)} (
		{getFilterButtonText(meta, filter)})
	</Button>
);
export default LineFilterButton;
