import { Image } from 'antd';
import {
	COUNTRY_TO_CCNUMBER,
	COUNTRY_TO_FLAG,
	FSR_ORDER_STATUS,
} from '../types/enums';

/*export const getOrderStatusIcon = (status) => {
  switch (status) {
    case ORDER_API_STATUS.COMPLETE:
      return <Image preview={false} className="order-status-icon" src="/icons/filter-completed.png" />;
    case ORDER_API_STATUS.BACKORDERED:
      return <Image preview={false} className="order-status-icon" src="/icons/error-filter.png" />;
    case ORDER_API_STATUS.CANCELLED:
      return <Image preview={false} className="order-status-icon" src="/icons/error-filter.png" />;
    case ORDER_API_STATUS.IN_PROGRESS:
      return <Image preview={false} className="order-status-icon" src="/icons/filter-warning.png" />;
    case ORDER_API_STATUS.SUBMITTED:
      return <Image preview={false} className="order-status-icon" src="/icons/filter-warning.png" />;
    case ORDER_API_STATUS.SHIPPED:
      return <Image preview={false} className="order-status-icon" src="/icons/filter-warning.png" />;
    default:
      return "";
  }
};*/

// return order status icon for the given status
export const getOrderStatusIcon = status => {
	switch (status) {
		case FSR_ORDER_STATUS.COMPLETED:
			return <Image preview={false} src="/icons/filter-completed.png" />;
		case FSR_ORDER_STATUS.NEEDATTENTION:
			return <Image preview={false} src="/icons/error-filter.png" />;
		case FSR_ORDER_STATUS.INPROGRESS:
			return <Image preview={false} src="/icons/filter-warning.png" />;
		default:
			return '';
	}
};

// return bullet icon based on the status of the bullet
export function returnSvg(fillRed) {
	return !fillRed ? (
		<svg
			key={Date.now() + '-' + Math.random()}
			width="8"
			height="8"
			viewBox="0 0 8 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ margin: '0 2px' }}>
			<circle cx="4" cy="4" r="3.5" fill="white" stroke="#D8D8D8" />
		</svg>
	) : (
		<svg
			key={Date.now() + '-' + Math.random()}
			width="8"
			height="8"
			viewBox="0 0 8 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<circle cx="4" cy="4" r="4" fill="#CA0D1B" />
		</svg>
	);
}

// returns FSR country saved in localstorage
export const getFsrCountry = () => {
	return localStorage.getItem('fsr_country') !== null
		? localStorage.getItem('fsr_country')
		: 'UK';
};

// returns FSR email saved in localstorage
export const getFsrEmail = () => {
	return localStorage.getItem('fsr_email') !== null
		? localStorage.getItem('fsr_email')
		: '-';
};

// returns FSR number saved in localstorage
export const getFsrNumber = () => {
	return localStorage.getItem('fsr_number') !== null
		? localStorage.getItem('fsr_number')
		: '-';
};

// returns FSR name saved in localstorage
export const getFsrName = () => {
	return localStorage.getItem('fsr_name') !== null
		? localStorage.getItem('fsr_name')
		: '-';
};

// returns FSR customer care number saved in localstorage
export const getFsrCustomerCareNumber = () => {
	const countryCode =
		localStorage.getItem('fsr_country') !== null
			? localStorage.getItem('fsr_country')
			: 'UK';
	return COUNTRY_TO_CCNUMBER[countryCode];
};

// returns FSR customer care number saved in localstorage
export const getgetCountryFlag = () => {
	const countryCode =
		localStorage.getItem('fsr_country') !== null
			? localStorage.getItem('fsr_country')
			: 'UK';
	return COUNTRY_TO_FLAG[countryCode];
};

// set base title
export const BASE_TITLE = 'J&J Customer Connect';
