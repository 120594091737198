export const showLogin = () => {
	window.location.href = `${
		process.env.REACT_APP_SSO_URL
	}/as/authorization.oauth2?client_id=${
		process.env.REACT_APP_SSO_CLIENT_ID
	}&response_type=code&scope=openid&redirect_uri=${encodeURIComponent(
		process.env.REACT_APP_URL,
	)}`;
};
export const getToken = data => {
	var body = Object.keys(data)
		.map(key => {
			return [encodeURIComponent(key) + '=' + encodeURIComponent(data[key])];
		})
		.join('&');

	return fetch(process.env.REACT_APP_SSO_URL + '/as/token.oauth2', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
		},
		body: body,
	}).then(res => res.json());
};

export const refreshToken = function () {
	var data = {
		grant_type: 'refresh_token',
		client_id: process.env.REACT_APP_SSO_CLIENT_ID,
		client_secret: process.env.REACT_APP_SSO_CLIENT_SECRET,
		refresh_token: JSON.parse(sessionStorage.getItem('fsrToken')).refreshToken,
	};
	var body = Object.keys(data)
		.map(key => {
			return [encodeURIComponent(key) + '=' + encodeURIComponent(data[key])];
		})
		.join('&');

	return fetch(process.env.REACT_APP_SSO_URL + '/as/token.oauth2', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
		},
		body: body,
	})
		.then(res => res.json())
		.then(res => {
			if (res.error) {
				showLogin();
				return;
			}
			sessionStorage.setItem('fsrToken', JSON.stringify(res));
		});
};

export const auditLogin = function () {
	const bodyJson = {
		fsr_number: parseInt(localStorage.getItem('fsr_number')),
		email: localStorage.getItem('fsr_email'),
	};
	fetch(process.env.REACT_APP_FSR_API + '/api/selfserve/login', {
		headers: {
			'Content-Type': 'application/json',
			Authorization:
				'Bearer ' + JSON.parse(sessionStorage.getItem('fsrToken')).access_token,
		},
		body: JSON.stringify(bodyJson),
		method: 'post',
	}).then(response => console.log(response));
};

export const isLoggedIn = () => {
	return sessionStorage.getItem('fsrToken') === null ? false : true;
};
