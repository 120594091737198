import { Button, Image } from 'antd';
import './../styles/orderFilter.scss';
import { FSR_ORDER_STATUS } from '../types/enums';

export const OrderFilter = ({ onClick, text, activeFilter, filter }) => {
	const getFilterIcon = () => {
		if (activeFilter === filter) {
			switch (filter) {
				case FSR_ORDER_STATUS.ALL:
					return '/icons/coolicon.png';
				case FSR_ORDER_STATUS.NEEDATTENTION:
					return '/icons/error-filter.png';
				case FSR_ORDER_STATUS.INPROGRESS:
					return '/icons/filter-warning.png';
				case FSR_ORDER_STATUS.COMPLETED:
					return '/icons/filter-completed.png';
				default:
					return '/icons/coolicon.png';
			}
		} else {
			switch (filter) {
				case FSR_ORDER_STATUS.ALL:
					return '/icons/filter-all.png';
				case FSR_ORDER_STATUS.NEEDATTENTION:
					return '/icons/error-filter.png';
				case FSR_ORDER_STATUS.INPROGRESS:
					return '/icons/filter-warning.png';
				case FSR_ORDER_STATUS.COMPLETED:
					return '/icons/filter-completed.png';
				default:
					return '/icons/filter-all.png';
			}
		}
	};
	return (
		<Button
			icon={
				<Image
					className="filter-button-icon"
					preview={false}
					src={getFilterIcon()}
				/>
			}
			onClick={onClick}
			className={
				(activeFilter === filter ? 'active-filter' : 'inactive-filter') +
				' filter'
			}>
			({text !== undefined ? (text > 99 ? '99+': text) : 0})
		</Button>
	);
};
