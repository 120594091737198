import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

if (localStorage.getItem('lang') === null) {
	localStorage.setItem('lang', 'en');
}
const resources = {
	en: {
		translation: {
			total: 'Total',
			hospitalNotes: 'Hospital Notes',
			supervisorEmail: 'Supervisor Email',
			supervisorPhoneNo: 'Supervisor Phone #',
			supervisorName: 'Supervisor Name',
			department: 'Department',
			orgName: 'Organisation Name',
			zipCode: 'Zip Code',
			state: 'State',
			country: 'Country',
			city: 'City',
			address1: 'Address 1',
			address2: 'Address 2',
			account: 'Account',
			accounts: 'Accounts',
			email: 'Email',
			Login: 'Login',
			WorkInformation: 'Work Information',
			preferredLanguage: 'Preferred Language',
			password: 'Password',
			rememberMe: 'Remember Me',
			forgotPassword: 'Forgot your password?',
			informationOnGo: 'Information on the go',
			welcomeString:
				'Welcome to the brand new JJCC mobile app! Here you will be able to access information regarding your orders anywhere and anytime through your mobile devices. ',
			getStarted: 'Get Started',
			dataRefresh: 'Data refresh & near real-time data',
			infoApp:
				'The information on the app reflect updates that are close to real-time. The data is also periodically refreshed to ensure you are given information that is accurate.',
			skipAll: 'Skip all',
			next: 'Next',
			orderStatuses: 'Order statuses',
			identifyOrderIssues:
				'To easily identify orders issues, orders have one of three statuses needs attentions, in progress or completed. The statuses are determined by the order’s products.',
			orderDate: 'Order Date',
			complete: 'Complete',
			updated: 'Updated',
			howStatusWork: 'How order statuses work',
			orderStatus:
				'An order status is determined by the product’s status within the order. The Needs Attention status will always take priority over the other statuses',
			letSeeExample: 'Let’s see an example!',
			ifAnyProduct: 'If any products are…',
			backOrdered: 'Backordered',
			ifAllProduct: 'Or if all products are…',
			canceled: 'Canceled',
			thenOrdered: 'Then the order …',
			needsAttention: 'Needs Attention',
			shipped: 'Shipped',
			inProgress: 'In Progress',
			submitted: 'Submitted',
			noOrderLine: 'And no order line items need attention then the order is …',
			completed: 'Completed',
			orIfSomeProd: 'Or if some products are',
			forExample: 'For example',
			orderStatusNeedAttention:
				'The order’s status is set to Needs Attention because a product is Backordered. You will also notice a progress bar representing the number of products that are Completed.',
			productCode: 'Product Code',
			productName: 'Product Name',
			quantity: 'Qty',
			estDelivery: 'Est. Delivery',
			viewProdSub: 'View Product Substitutions',
			statusIndividualProd:
				'See the status of individual products within an order and related status information. When applicable, you will also be able to view product substitutions.',
			seeWebApp: 'Save the website as an app',
			seeWebAppBrowser:
				'Depending on the browser used, you will be able to save the website like any other application on your device. Follow the instructions above.',
			appleUserOnly: 'Apple Users (Safari Only)',
			tapShareButton: 'Tap the Share Button',
			tapHomeScreen: 'Tap Add to Home Screen',
			enterNameSafari:
				'Enter the name for the shortcut and tap Add. Safari will close automatically and you will see the shortcut on your home screen.',
			androidUserOnly: 'Android Users (Google Chrome Only)',
			tapMenuIcon: 'Tap the Menu Icon',
			enterNameShortcut: 'Enter the name for the shortcut and tap Add',
			oneLastThing: 'One last thing!',
			helpImproveExperience:
				'Help us improve your experience! We’re conducting on-going research to make this app even better and would love your input! Would you like us to reach out to you for your feedback at some point?',
			mayBeLater: 'Maybe Later',
			yesIdLove: "Yes, I'd love to help!",
			refreshPage:
				'Somethng went wrong. Please refresh the page to load data. ',
			dashboardComingSoon: 'Dashboard coming soon!',
			feedbackStringInfo:
				'In the meantime, feel free to use the orders and accounts pages to access information.',
			feedbackString:
				'If you have feedback that you want to share with us regarding any pages, including the dashboard, navigate to the support page to opt in to future feedback sessions.',
			accountUpdates: 'Accounts with Order Updates',
			allAccounts: 'All Accounts',
			searchAccountName: 'Search by account name, account#, or postal code',
			productSearch: 'Search by product name or product code',
			orders: 'Orders',
			noUpdates: 'No updates',
			searchOrder: 'Search by order#, po#, or product code or name',
			all: 'All',
			pastMonth: 'Past Month',
			clickProduct: 'Click a product to call a customer service representative',
			each: 'Each',
			match: 'Match',
			call: 'Call',
			placeOrder: 'Place Order Buyer Group, CSR Group',
			contactInformation: 'Contact Information',
			notificationEmail: 'Notification Email',
			phoneNo: 'Phone #',
			mobileNo: 'Mobile #',
			faxNo: 'Fax #',
			addresses: 'Addresses',
			support: 'Support',
			infoGuide: 'Information Guide',
			readStatuses: 'How do I read order statuses?',
			readLine: 'How do I read order line items?',
			leaveFeedback: 'Leave Feedback',
			contactedFeedback: 'I want to be contacted to provide my feedback',
			callCustomerService: 'Call Customer Service',
			supportemail: '<DL-SelfServeApp@its.jnj.com>',
			statuses:
				'To easily understand the status of your various orders, they will be classified under 1 of 3 statuses: needs attention, in progress, or completed. ',
			letSeeHowThisWork: 'Let’s see how this works!',
			backToSupport: 'Back to Support',
			howStatusesWork: 'How order statuses work',
			orderStatusProd:
				'An order status is determined by the product’s status within the order. The Needs Attention status will always take priority over the other statuses',
			pleaseEnterEmail: 'Please Enter Your Email',
			pleaseEnterPassword: 'Please Enter Your Password',
			letseeHowThisWork: 'Let’s see how this works!',
			ordeLineItemStatus: 'Order line item status',
			orderBrokenLineItems:
				'Orders will be broken down into individual order line items to allow information relating directly to its order status to be easily identified. When applicable, product substitutions can be viewed.',
			updatedDate: 'Updated Date',
			order: 'Order',
			products: 'Products',
			productSubstitution: 'Product Substitutions',
			qty: 'QTY',
			delivered: 'Delivered',
			noProductSubstitution: 'No product substitute available',
			howNavBack: 'How to navigate back',
			navBackDesc: 'You have two ways to navigate back. use the back button at the top left corner of your app, or swipe right or left from the edge of your screen.'
		},
	},
	fr: {
		translation: {
			total: 'Le total',
			hospitalNotes: "Notes d'hôpital",
			supervisorEmail: 'Courriel du superviseur',
			supervisorPhoneNo: 'Numéro de téléphone du superviseur',
			supervisorName: 'Nom du superviseur',
			department: 'département',
			orgName: "Nom de l'organisme",
			zipCode: 'Code postal',
			state: 'État',
			country: 'Pays',
			city: 'Ville',
			address1: 'Adresse 1',
			Address1: 'Adresse 1',
			address2: 'Adresse 2',
			Address2: 'Adresse 2',
			account: 'Compte',
			Account: 'Compte',
			accounts: 'Comptes',
			email: 'Email',
			Login: 'Se connecter',
			WorkInformation: 'informations sur le travail',
			preferredLanguage: 'Langue Préférée',
			password: 'Mot de passe',
			rememberMe: 'Se souvenir de mon identifiant',
			forgotPassword: 'Mot de passe oublié',
			informationOnGo: 'Quelques informations',
			welcomeString:
				"Bienvenue dans la toute nouvelle application mobile de JJCC, destinée à vous fournir des informations sur l'état de vos commandes, ceci n'importe quand et de n'importe où depuis votre téléphone.",
			getStarted: "C'est parti",
			dataRefresh: 'Actualisation des données en quasi temps réel',
			infoApp:
				"Les informationd de l'application reflètent des mises à jour qui sont proches du temps réel. Les données sont aussi actualisées periodiquement afin de garantir l'exactitudes des informations.",
			skipAll: 'Passer',
			next: 'Suivant',
			orderStatuses: 'Statuts de commande',
			identifyOrderIssues:
				"Pour identifier facilement les problèmes liés aux commandes, les commandes ont l'un des trois statuts suivants : besoins d'attention, en cours ou terminés. Les statuts sont déterminés par les produits de la commande. Voyons comment cela fonctionne !",
			orderDate: 'Date de commande',
			complete: 'Complète',
			updated: 'Mis à jour',
			order: 'Commande',
			howStatusWork: 'Fonctionnement des statuts de commande',
			orderStatus:
				'Un statut de commande est déterminé par le statut du produit dans la commande. Le statut "Attention requise" sera toujours prioritaire sur les autres statuts',
			letSeeExample: 'Voyons un exemple!',

			ifAnyProduct: 'Si un des produits est…',
			backOrdered: 'En ruptude de stock',
			Backordered: 'En ruptude de stock',
			ifAllProduct: 'Ou si tous les produits sont…',
			canceled: 'Annulée',
			Canceled: 'Annulée',
			thenOrdered: 'Alors la commande…',
			needsAttention: 'Attention requise',
			shipped: 'Expédiée',
			Shipped: 'Expédiée',
			inProgress: 'En cours',
			submitted: 'Soumis',
			Submitted: 'Soumis',
			noOrderLine:
				"Et qu'aucune autre ligne de commanden'a besoin d'attention, alors la commande est…",
			completed: 'Terminé',
			Completed: 'Terminé',
			orIfSomeProd: 'Ou si certain produits sont',
			forExample: 'Par exemple',
			orderStatusNeedAttention:
				'Le statut de la commande est défini sur Attention requise car un produit est en rupture de stock. Vous remarquerez également une barre de progression représentant le nombre de produits complets.',
			productCode: 'Nom du produit',
			quantity: 'Quantité',
			estDelivery: 'Livraison estimée',
			viewProdSub: 'Voir le produit de substitution',
			statusIndividualProd:
				"Voir l'état des produits dans une commande et les informations d'état associées. Quand c'est disponible, vous pourrez également voir les substitutions de produits.",
			productName: 'Nom du produit',
			seeWebApp: 'Enregistrer le site commme application',
			seeWebAppBrowser:
				"Selon le navigateur utilisé, vous pourrez enregistrer le site Web comme n'importe quelle autre application sur votre appareil. Suivez les instructions ci-dessus.",
			appleUserOnly: 'Utilisateurs Apple (Safari uniquement)',
			tapShareButton: 'Appuyez sur le boutton partager',
			tapHomeScreen: "Appuyez sur ajouter sur l'écran principal",
			enterNameSafari:
				"Saisissez le nom du raccourci et appuyez sur Ajouter. Safari se fermera automatiquement et vous verrez le raccourci sur l' écran d'accueil de votre appareil.",
			androidUserOnly: 'Utilisateurs Android (Google Chrome seulement)',
			tapMenuIcon: "Appuyez sur l'icone Menu",
			enterNameShortcut: 'Entrez le nom du raccourci et appuyez sur Ajouter',
			oneLastThing: 'Dernière chose!',
			helpImproveExperience:
				'Aidez-nous à améliorer votre expérience ! Nous menons des recherches pour rendre cette application encore meilleure et nous aimerions votre aide ! Souhaiteriez-vous être contacté afin de nous donner vos commentaires ? ',
			mayBeLater: 'Peut-être plus tard',
			yesIdLove: "Oui, j'aimerai aider!",
			refreshPage:
				"Quelque chose n'a pas fonctionné. Veuillez actualiser la page pour charger les données.",
			dashboardComingSoon: 'Tableau de bord disponible prochainement!',
			feedbackStringInfo:
				"En attendant, n'hésitez pas à utiliser les pages commandes et comptes pour accéder aux informations.",
			feedbackString:
				"Si vous avez des commentaires que vous souhaitez partager avec nous concernant des pages, y compris le tableau de bord, accédez à la page d'assistance pour vous inscrire aux futures sessions de commentaires.",
			accountUpdates: 'Mis à jour des comptes',
			allAccounts: 'Tous les comptes',
			searchAccountName:
				'Chercher par nom de compte, numéro de compte, ou code postal',
			productSearch: 'Search by product name or product code',
			orders: 'Commandes',
			noUpdates: 'Pas de mise à jour',
			searchOrder:
				'Chercher par numéro de commande JnJ, numéro de commande client, nom de produit, ou code produit ',
			all: 'Tous',
			All: 'Tous',
			pastMonth: 'Mois passé',
			clickProduct: 'Cliquez sur un produit pour appeler votre service clients',
			each: 'Unité',
			match: 'Correspondance',
			call: 'Appel',
			placeOrder: "Placer une commande Groupe d'acheteurs, Groupe CSR",
			contactInformation: 'Information de contact',
			notificationEmail: 'Notification Email',
			phoneNo: 'Numéro de téléphone',
			mobileNo: 'Numéro de téléphone mobile',
			faxNo: 'Numéro de Fax',
			addresses: 'Adresse',
			support: 'Support',
			infoGuide: "Guide d'information",
			readStatuses: 'Comment lire les status de commande?',
			readLine: 'Comment lire les lignes de commande?',
			leaveFeedback: 'Laisser un commantaire',
			contactedFeedback: "J'aimerai être contacté pour partager mon avis",
			callCustomerService: 'Appel au service clients',
			statuses:
				"Pour comprendre facilement le statut de vos différentes commandes, celles-ci seront classées sous 1 des 3 statuts suivants : besoin d'attention, en cours, ou terminé. Voyons comment cela fonctionne !",
			letSeeHowThisWork: 'Voyons comment cela fonctionne !',
			backToSupport: 'Retour au support',
			howStatusesWork: 'Comment fonctionnent les status',
			orderStatusProd:
				'Un statut de commande est déterminé par le statut du produit dans la commande. Le statut Attention requise sera toujours prioritaire sur les autres statuts Voyons un exemple!',
			pleaseEnterEmail: 'Entrez votre email',
			pleaseEnterPassword: 'Entrez votre mot de passe',
			ordeLineItemStatus: 'Statut de la ligne de commande',
			orderBrokenLineItems:
				"Les commandes seront décomposées en lignes de commande individuelles pour permettre d'identifier facilement les informations directement liées à l'état de la commande. Le cas échéant, les substitutions de produits peuvent être consultées.",
			updatedDate: 'Date de mise à jour',
			products: 'Produits',
			Products: 'Produits',
			productSubstitution: 'Produits de substitution',
			'Needs Attention': 'Attention requise',
			'In Progress': 'In Bearbeitung',
			qty: 'qté'.toUpperCase(),
			delivered: 'Livré',
			noProductSubstitution: 'Aucun substitut de produit disponible',
		},
	},
	de: {
		translation: {
			total: 'Gesamt',
			hospitalNotes: 'Krankenhausnotizen',
			supervisorEmail: 'Supervisor-E-Mail',
			supervisorPhoneNo: 'Telefonnummer des Vorgesetzten',
			supervisorName: 'Name des Vorgesetzten',
			department: 'Abteilung',
			orgName: 'Organisationsname',
			zipCode: 'Postleitzahl',
			state: 'Zustand',
			country: 'Land',
			city: 'Stadt',
			address1: 'Adresse 1',
			address2: 'Adresse 2',
			account: 'Konto',
			accounts: 'Konten',
			email: 'E-Mail',
			Login: 'Anmeldung',
			WorkInformation: 'Work Information',
			preferredLanguage: 'Preferred Language',
			password: 'Passwort',
			rememberMe: 'Sich an mein Login erinnern',
			forgotPassword: 'Passwort vergessen?',
			informationOnGo: 'Ein paar Informationen',
			welcomeString:
				'Willkommen bei der brandneuen JJCC Mobile App! Hier können Sie jederzeit und überall über Ihre mobilen Geräte auf Informationen zu Ihren Bestellungen zugreifen.',
			getStarted: "Los geht's",
			dataRefresh: 'Datenaktualisierung und Daten nahezu in Echtzeit',
			infoApp:
				'Die Informationen auf der App spiegeln Aktualisierungen wider, die nahezu in Echtzeit erfolgen. Die Daten werden ausserdem regelmässig aktualisiert, um sicherzustellen, dass Sie genaue Informationen erhalten.',
			skipAll: 'Alles überspringen',
			next: 'Nächster Schritt',
			orderStatuses: 'Status der Bestellung',
			identifyOrderIssues:
				'Um Probleme mit Aufträgen leicht erkennen zu können, haben Aufträge einen von drei Status: braucht Aufmerksamkeit, in Bearbeitung oder abgeschlossen. Die Status werden durch die Produkte in der Bestellung bestimmt. Schauen wir mal, wie das funktioniert!',
			orderDate: 'Datum der Bestellung',
			complete: 'Vollständig',
			updated: 'Aktualisiert',
			order: 'Bestellung',
			howStatusWork: 'Wie der Auftragsstatus funktioniert',
			orderStatus:
				'Der Status einer Bestellung wird durch den Status des Produkts innerhalb der Bestellung bestimmt. Der Status ""braucht Aufmerksamkeit"" hat immer Vorrang vor den anderen Status.',
			letSeeExample: 'Sehen wir uns ein Beispiel an!',
			ifAnyProduct: 'Wenn eines der Produkte…',
			backOrdered: 'Bestellrückstand',
			BackOrdered: 'Bestellrückstand',
			ifAllProduct: 'Oder wenn alle Produkte…',
			canceled: 'Annulliert',
			thenOrdered: 'Dann ist die Bestellung….',
			needsAttention: 'Braucht Aufmerksamkeit',
			'Needs Attention': 'Braucht Aufmerksamkeit',
			shipped: 'Verschickt',
			inProgress: 'In Bearbeitung',
			submitted: 'Eingereicht',
			noOrderLine:
				'Und wenn keine Auftragsposition Aufmerksamkeit braucht, dann ist der Auftrag ...',
			completed: 'Vollständig',
			Completed: 'Vollständig',
			orIfSomeProd: 'Wenn gewisse Produkte…',
			forExample: 'Zum Beispiel',
			orderStatusNeedAttention:
				'Der Status der Bestellung ist auf "braucht Aufmerksamkeit" gesetzt, da ein Produkt im Rückstand ist. Sie sehen auch eine Fortschrittsanzeige, die die Anzahl der Produkte anzeigt, die vollständig sind.',
			productCode: 'Artikel-Nr.',
			productName: 'Produktname',
			quantity: 'Menge',
			estDelivery: 'Voraussichtliche Lieferung',
			visualViewport: 'Ersatzartikel ansehen',
			statusIndividualProd:
				'Sie können den Status der einzelnen Produkte innerhalb einer Bestellung und die zugehörigen Statusinformationen einsehen. Falls zutreffend, können Sie auch Ersatzartikel einsehen.',
			seeWebApp: 'Speichern Sie die Website als App',
			seeWebAppBrowser:
				'Je nach verwendetem Browser können Sie die Website wie jede andere Anwendung auf Ihrem Gerät speichern. Folgen Sie den obigen Anweisungen.',
			appleUserOnly: 'Apple-Anwender (nur Safari)',
			tapShareButton: 'Klicken Sie auf den "Teilen"-Knopf',
			tapHomeScreen: 'Klicken Sie auf "Startbildschirm"',
			enterNameSafari:
				'Geben Sie den Namen für Ihre gewünschte Abkürzung ein und klicken Sie auf "Hinzufügen". Safari wird automatisch geschlossen und Sie sehen die Abkürzung auf Ihrem Startbildschirm.',
			androidUserOnly: 'Android-Anwender (nur Google Chrome)',
			tapMenuIcon: 'Klicken Sie auf den "Menu"-Knopf',
			enterNameShortcut:
				'Geben Sie den Namen für Ihre gewünschte Abkürzung ein und klicken Sie auf "Hinzufügen"',
			oneLastThing: 'Eine letzte Sache!',
			helpImproveExperience:
				'Helfen Sie uns, Ihr Erlebnis zu verbessern! Wir führen laufend Untersuchungen durch, um diese App noch besser zu machen und würden uns über Ihren Input freuen! Dürfen wir Sie kontaktieren, um Ihr Feedback einzuholen?',
			mayBeLater: 'Vielleicht später',
			yesIdLove: 'Ja, ich möchte gerne helfen!',
			refreshPage:
				'Etwas ist schiefgelaufen. Bitte aktualisieren Sie die Seite, um die Daten neu zu laden.',
			dashboardComingSoon: 'Dashboard kommt bald!',
			feedbackStringInfo:
				'In der Zwischenzeit können Sie die Seiten für die Bestellungen und Konten nutzen, um Informationen abzurufen. ',
			feedbackString:
				'Wenn Sie uns Feedback zu einer der Seiten, einschliesslich des Dashboards, geben möchten, gehen Sie auf die Support-Seite, um sich für zukünftige Feedbacksitzungen anzumelden.',
			accountUpdates: 'Aktualisierung der Konten',
			allAccounts: 'Alle Konten',
			searchAccountName: 'Suche nach Kontoname, Konto-Nr. oder PLZ',
			orders: 'Bestellungen',
			noUpdates: 'Keine Aktualisierung',
			searchOrder:
				'Suche anhand einer JnJ Bestell-Nr., Ihrer eigenen Bestell-Nr., nach Artikel-Nr. oder Produktbezeichnung',
			all: 'Alle',
			pastMonth: 'Letzter Monat',
			clickProduct:
				'Klicken Sie auf ein Produkt, um den Kundendienst anzurufen',
			each: 'Stück',
			match: 'Übereinstimmung',
			call: 'Anruf',
			placeOrder: 'Bestellung für Einkaufsgruppe oder CSR-Gruppe eingeben',
			contactInformation: 'Kontaktinformationen',
			notificationEmail: 'E-Mail-Benachrichtigung',
			phoneNo: 'Telefon-Nr.',
			mobileNo: 'Mobil-Nr.',
			faxNo: 'Fax-Nr.',
			addresses: 'Adressen',
			support: 'Hilfe',
			infoGuide: 'Leitfaden',
			readStatuses: 'Wie kann ich den Auftragsstatus ablesen?',
			readLine: 'Wie lese ich Auftragspositionen?',
			leaveFeedback: 'Kommentar hinterlassen',
			contactedFeedback:
				'Ich möchte kontaktiert werden, um mein Feedback zu geben',
			callCustomerService: 'Kundendienst anrufen',
			statuses:
				'Um den Status Ihrer verschiedenen Aufträge leicht zu verstehen, werden diese in einen von 3 Status eingeteilt: braucht Aufmerksamkeit, in Bearbeitung oder abgeschlossen. Schauen wir mal, wie das funktioniert!',
			backToSupport: 'Zurück zu Hilfe',
			orderStatusProd:
				'Der Status einer Bestellung wird durch den Status des Produkts innerhalb der Bestellung bestimmt. Der Status ""braucht Aufmerksamkeit"" hat immer Vorrang vor den anderen Status. Sehen wir uns ein Beispiel an!',
			pleaseEnterEmail: 'Bitte geben Sie Ihre E-Mail ein',
			pleaseEnterPassword: 'Bitte geben Sie Ihr Passwort ein',
			letSeeHowThisWork: 'Mal sehen, wie das funktioniert!',
			ordeLineItemStatus: 'Status der Auftragsposition',
			orderBrokenLineItems:
				'Bestellungen werden in einzelne Bestellposten unterteilt, um eine einfache Identifizierung von Informationen zu ihrem Bestellstatus zu ermöglichen. Gegebenenfalls können Produktsubstitutionen angezeigt werden.',
			updatedDate: 'Aktualisiertes Datum',
			products: 'Produkte',
			productSubstitution: 'Produktsubstitutionen',
			noProductSubstitution: 'Geen Produktsubstitutionen beschikbaar',
		},
	},
};
i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: localStorage.getItem('lang'), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
		// you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
		// if you're using a language detector, do not define the lng option
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default resources;
